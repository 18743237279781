import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {} from 'googlemaps';


declare var $: any;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
	@ViewChild('map', { static: true}) mapElement: any;
	map: google.maps.Map;
	constructor (public router:Router) { }
	tools: any[];
	ngOnInit () {
		window.scrollTo(0,0);
		this.initTools();
		const mapProperties = {
			center: new google.maps.LatLng(30.606430, 76.857832),
			zoom: 16,
			mapTypeId: google.maps.MapTypeId.ROADMAP
	   };
	   this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
	   this.TestMarker();
	}

	// Function for adding a marker to the page.
    addMarker(location) {
        new google.maps.Marker({
            position: location,
			map: this.map,
			label: { color: '#522d2e', fontWeight: 'bold', fontSize: '14px', text: 'MacMaster India Pvt. Ltd.' }
        });
    }

    // Testing the addMarker function
    TestMarker() {
           const CentralPark = new google.maps.LatLng(30.606430, 76.857832);
           this.addMarker(CentralPark);
    }

	initTools () {
		this.tools = [
			{
				name: 'Torque Screw Driver',
				img: 'assets/img/t1.jpg',
				id: 'torque-wrenches'
			},
			{
				name: 'Torque Wrench (Standard)',
				img: 'assets/img/torque_wrench_ratchet_type.jpg',
				id: 'torque-wrenches'
			},
			{
				name: 'Torque Wrench (Rachet Type)',
				img: 'assets/img/t3.jpg',
				id: 'torque-wrenches'
			},
			{
				name: 'Extension Socket',
				img: 'assets/img/t4.jpg',
				id: 'extension-bars'
			},
			{
				name: 'Crow Foot Wrench',
				img: 'assets/img/crow-foot-wrench.jpg',
				id: 'slugging-wrenches'
			},
			{
				name: 'Extension Sockets',
				img: 'assets/img/extension-sockets.jpg',
				id: 'industrial-sockets'
			},
			{
				name: 'Hex Socket',
				img: 'assets/img/t7.jpg',
				id: 'impact-sockets'
			},
			{
				name: 'L-Handle',
				img: 'assets/img/l-handle.jpg',
				id: 'angle-handle'
			},			
			{
				name: 'Swivel Impact Joint',
				img: 'assets/img/swivel-impact-joint.jpg',
				id: 'swivel-handles'
			},
			{
				name: 'Hex Socket',
				img: 'assets/img/t11.jpg',
				id: 'hex-drivers'
			},
			{
				name: 'Open Jaw Slugging Wrench',
				img: 'assets/img/open-jaw-slugging-wrench.jpg',
				id: 'slugging-wrenches'
			},
			{
				name: 'Ratchet Spanner',
				img: 'assets/img/ratchett-spinner.jpg',
				id: 'ratcheting-spanners'
			},
			{
				name: 'Impact Hex Adaptor',
				img: 'assets/img/impact-hex-adaptor.jpg',
				id: 'hex-drivers'
			},
			{
				name: 'Impact Square Drive Adaptor',
				img: 'assets/img/impact-square-drive-adaptor.jpg',
				id: 'square-drive-adaptors'
			},	
			{
				name: 'Hex Socket',
				img: 'assets/img/t17.jpg',
				id: "hex-drivers"
			},
			{
				name: 'Reversible Ratchet',
				img: 'assets/img/reversible-ratchet.jpg',
				id: 'reversible-ratchets'
			},
			{
				name: 'Slugging Wrench',
				img: 'assets/img/slugging-wrench.jpg',
				id: 'slugging-wrenches'
			},
			{
				name: 'T-Handle',
				img: 'assets/img/t-handle.jpg',
				id: 'sliding-t-handles'
			},
			{
				name: 'Hex Driver',
				img: 'assets/img/hex-driver.jpg',
				id: "hex-drivers"
			},
		];
	}
	ngAfterViewInit () {

		$("#scroller").simplyScroll({
			speed: 1
		});

		$('.carousel').carousel({
			interval: 150000
		})
	}

	openToolInfo(tool){
		this.router.navigate([`product/${tool.id}`]);
		// this.router.navigate([`product/1`]);
	}

}
