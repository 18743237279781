import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-download',
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

	downloadList = [];
	constructor () { }

	ngOnInit () {
		this.downloadList = [
			{
				name: "Master Catalogue",
				path: 'assets/master_catalogue.pdf'
			},
			{
				name: "Torque Wrench Catalogue",
				path: 'assets/torque_wrench_catalogue.pdf'
			}
		]
	}

	download (item) {
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = item.path;
		a.download = item.name + ".pdf";
		document.body.appendChild(a);
		a.click();
	}

}
