import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.css"],
})
export class CalculatorComponent implements OnInit {
  @Output() close = new EventEmitter();
  results: any = {};

  calcForm = new FormGroup({
    input: new FormControl(),
    units: new FormControl("ozf-in"),
  });
  constructor() {}

  ngOnInit(): void {}

  closeCalc() {
    this.close.emit("");
  }

  reset() {
    this.calcForm.setValue({ units: "ozf-in", input: "" });
    this.results = {};
  }

  onSubmit() {
    console.log(this.calcForm);
    if (this.calcForm.value.input) {
      const inputValue = this.calcForm.value.input;
      const unit = this.calcForm.value.units;
      switch (unit) {
        case "lbf-ft":
          this.results["gf-cm"] = inputValue * 13800;
          this.results["kgf-cm"] = inputValue * 13.8;
          this.results["kgf-m"] = inputValue * 0.138;
          this.results["mN-m"] = inputValue * 1360;
          this.results["cN-m"] = inputValue * 136;
          this.results["N-m"] = inputValue * 1.36;
          this.results["ozf-in"] = inputValue * 192;
          this.results["lbf-in"] = inputValue * 12;
          this.results["lbf-ft"] = inputValue;
          break;
        case "lbf-in":
          this.results["gf-cm"] = inputValue * 1150;
          this.results["kgf-cm"] = inputValue * 1.15;
          this.results["kgf-m"] = inputValue * 0.0115;
          this.results["mN-m"] = inputValue * 113;
          this.results["cN-m"] = inputValue * 11.3;
          this.results["N-m"] = inputValue * 0.113;
          this.results["ozf-in"] = inputValue * 16;
          this.results["lbf-in"] = inputValue;
          this.results["lbf-ft"] = inputValue * 0.0833;
          break;
        case "N-m":
          this.results["gf-cm"] = inputValue * 10200;
          this.results["kgf-cm"] = inputValue * 10.2;
          this.results["kgf-m"] = inputValue * 0.102;
          this.results["mN-m"] = inputValue * 1000;
          this.results["cN-m"] = inputValue * 100;
          this.results["N-m"] = inputValue;
          this.results["ozf-in"] = inputValue * 142;
          this.results["lbf-in"] = inputValue * 8.85;
          this.results["lbf-ft"] = inputValue * 0.738;
          break;
        case "cN-m":
          this.results["gf-cm"] = inputValue * 102;
          this.results["kgf-cm"] = inputValue * 0.102;
          this.results["kgf-m"] = inputValue * 0.00102;
          this.results["mN-m"] = inputValue * 10;
          this.results["cN-m"] = inputValue;
          this.results["N-m"] = inputValue * 0.01;
          this.results["ozf-in"] = inputValue * 1.42;
          this.results["lbf-in"] = inputValue * 0.0885;
          this.results["lbf-ft"] = inputValue * 0.00738;
          break;
        case "mN-m":
          this.results["gf-cm"] = inputValue * 10.2;
          this.results["kgf-cm"] = inputValue * 0.0102;
          this.results["kgf-m"] = inputValue * 0.000102;
          this.results["mN-m"] = inputValue;
          this.results["cN-m"] = inputValue * 0.1;
          this.results["N-m"] = inputValue * 0.001;
          this.results["ozf-in"] = inputValue * 0.142;
          this.results["lbf-in"] = inputValue * 0.00885;
          this.results["lbf-ft"] = inputValue * 0.000738;
          break;
        case "gf-cm":
          this.results["gf-cm"] = inputValue;
          this.results["kgf-cm"] = inputValue / 1000;
          this.results["kgf-m"] = inputValue / 100000;
          this.results["mN-m"] = inputValue * 0.0981;
          this.results["cN-m"] = inputValue * 0.00981;
          this.results["N-m"] = inputValue * 0.0000981;
          this.results["ozf-in"] = inputValue * 0.0139;
          this.results["lbf-in"] = inputValue * 0.000868;
          this.results["lbf-ft"] = inputValue * 0.0000723;
          break;
        case "kgf-cm":
          this.results["gf-cm"] = inputValue * 1000;
          this.results["kgf-cm"] = inputValue;
          this.results["kgf-m"] = inputValue / 100;
          this.results["mN-m"] = inputValue * 98.1;
          this.results["cN-m"] = inputValue * 9.81;
          this.results["N-m"] = inputValue * 0.0981;
          this.results["ozf-in"] =
            Math.round(inputValue * 0.0139 * 1000 * 100) / 100;
          this.results["lbf-in"] = inputValue * 0.868;
          this.results["lbf-ft"] = inputValue * 0.0723;
          break;
        case "kgf-m":
          this.results["gf-cm"] = inputValue * 100000;
          this.results["kgf-cm"] = inputValue * 100;
          this.results["kgf-m"] = inputValue;
          this.results["mN-m"] = inputValue * 9810;
          this.results["cN-m"] = inputValue * 981;
          this.results["N-m"] = inputValue * 9.81;
          this.results["ozf-in"] =
            Math.round(inputValue * 0.0139 * 1000 * 100 * 100) / 100;
          this.results["lbf-in"] = inputValue * 86.8;
          this.results["lbf-ft"] = inputValue * 7.23;
          break;
        case "ozf-in":
          this.results["gf-cm"] = inputValue * 72;
          this.results["kgf-cm"] = inputValue * 0.072;
          this.results["kgf-m"] = inputValue * 0.00072;
          this.results["mN-m"] = inputValue * 7.06;
          this.results["cN-m"] = inputValue * 0.706;
          this.results["N-m"] = inputValue * 0.00706;
          this.results["ozf-in"] = inputValue;
          this.results["lbf-in"] = inputValue * 0.0625;
          this.results["lbf-ft"] = inputValue * 0.00521;
          break;
        default:
          break;
      }
    }
  }

  // oz Ounce Forze
  // gf Gram Force
  // mN.m milliNeuton Meter
  // N.cm  Neuton Centimeter
  // lbf·in Pound-force  inch
  // lbf·ft Pound-force  foot

  // ozf inch - gf cm -> 1 -> 72
  // ozf inch - kgf cm -> 1 -> 72/1000 => 0.072
  // ozf inch - kgf m -> 1 -> 72/10000 => 0.00072

  // ozf inch - mN.m -> 1 -> 7.06
  // ozf inch - N.cm -> 1 -> 0.706
  // ozf inch - N.m -> 1 -> 0.00706
  // ozf inch - lbf·in -> 1 -> 0.0625
  // ozf inch - lbf·ft -> 1 -> 0.00521

  // gfcm - ozf.in -> 1 -> 0.0139
  // gfcm - lbf.in -> 1 -> 0.000868
  // gfcm - lbf.ft -> 1 -> 0.0000723
  // gfcm - mN.m -> 1 -> 0.0981
  // gfcm - N.cm -> 1 -> 0.00981
  // gfcm - N.m -> 1 -> 0.0000981
}

// units = [
//   'ozf-in',
//   'gf-cm',
//   'kgf-cm',
//   'kgf-m',
//   'mN-m',
//   'cN-m',
//   'N-m',
//   'lbf-in',
//   'lbf-ft',
// ];
