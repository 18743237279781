import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../constants.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	showSubProducts = false;
	constructor (public constants: ConstantsService) { }

	ngOnInit () {
	}

	mouseEnter (product) {
		if (!product.products) {
			return;
		}
		this.showSubProducts = true;
	}

	mouseLeave (product) {
		if (!product.products) {
			return;
		}
		this.showSubProducts = false;
	}
}
