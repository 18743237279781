import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastContainerDirective, ToastrService } from "ngx-toastr";
import { of } from "rxjs";

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "macmaster";
  dealerForm: FormGroup;
  selectedItem = "home";
  showCalculator = false;
  @ViewChild("closeDealerModal", { static: false })
  public closeDealerModal: ElementRef;
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  constructor(private httpClient: HttpClient, private toastr: ToastrService) {}

  ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
    $(".navbar-collapse a").click(function () {
      $(".navbar-collapse").collapse("hide");
    });
    this.dealerForm = new FormGroup({
      companyName: new FormControl(),
      email: new FormControl(),
      phoneNumber: new FormControl(),
      name: new FormControl(),
      city: new FormControl(),
      state: new FormControl("Select State"),
    });
  }

  submit() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.httpClient
      .post("https://macmaster.herokuapp.com/sendmail", this.dealerForm.value, {
        headers: headers,
      })
      .subscribe(
        () => {
          this.dealerForm.reset();
          this.showSuccess();
          this.closeDealerModal.nativeElement.click();
        },
        () => {
          this.dealerForm.reset();
          this.showSuccess();
          this.closeDealerModal.nativeElement.click();
        }
      );
  }

  showSuccess() {
    this.toastr.success(
      "Thank you for showing interest. Our team with contact you soon."
    );
  }
}
