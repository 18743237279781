import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { ToolComponent } from "./tool/tool.component";
import { HomeComponent } from "./home/home.component";
import { DownloadComponent } from "./download/download.component";
import { ContactComponent } from "./contact/contact.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "product/:id",
    component: ToolComponent,
  },
  {
    path: "downloads",
    component: DownloadComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "*",
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
