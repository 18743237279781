import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

	@Input('color') public color = 'black';
	@ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
	enquiryForm: FormGroup;
	constructor(private httpClient: HttpClient,
		private toastr: ToastrService) { }
	ngOnInit() {
		this.toastr.overlayContainer = this.toastContainer;
		this.enquiryForm = new FormGroup({
			requirements: new FormControl(),
			email: new FormControl(),
			phoneNumber: new FormControl(),
			name: new FormControl()
		});
	}

	submit() {
		let headers = new HttpHeaders();
		headers.append('Content-Type', 'application/json');
		return this.httpClient.post('https://macmaster.herokuapp.com/sendEnquiry', this.enquiryForm.value, {
			headers: headers
		})
			.subscribe(() => {
				this.enquiryForm.reset();
				this.showSuccess();
			}, () => {
				this.enquiryForm.reset();
				this.showSuccess();
			});
	}

	showSuccess() {
		this.toastr.success('Thank you for showing interest. Our team with contact you soon.');
	}

}
