import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  constructor() {}

  toolsDataInfo = {
    "open-jaw-torque-wrenches": {
      images: ["assets/img/open-jaw-torque-wrench.jpg"],
      data: ["./assets/data/open-jaw-torque-wrench.json"],
    },
    "torque-wrenches": {
      images: ["assets/img/c9_test.png"],
      data: [
        "./assets/data/torqueWrenchNonRachet.json",
        "./assets/data/torqueScrewDriver.json",
      ],
    },
    "impact-sockets": {
      images: [
        "./assets/img/extension-sockets.jpg",
        "./assets/img/t4.jpg",
        "./assets/img/t7.jpg",
        "./assets/img/t17.jpg",
      ],
      data: [
        "./assets/data/1-2_Sq.Drive_Single_Hex_Impact_Socket(METRIC)_(L).json",
        "./assets/data/1-4_Sq._Drive_Single_Hex_Impact_Deep_Socket_(American)_(D).json",
        "./assets/data/1-4_Sq._Drive_Single_Hex_Impact_Deep_Socket_(Metric)_(D).json",
        "./assets/data/1-4_Sq._Drive_Single_Hex_Impact_Socket_(American)_(C).json",
        "./assets/data/1-4_Sq._Drive_Single_Hex_Impact_Socket_(Metric)_(C).json",
        "./assets/data/3-8_Sq._Drive_Single_Hex_Deep_Impact_Socket_(American)_(H).json",
        "./assets/data/3-8_Sq._Drive_Single_Hex_Deep_Impact_Socket_(Metric)_(H).json",
        "./assets/data/3-8_Sq._Drive_Single_Hex_Impact_Socket_(Amercian)_(G).json",
        "./assets/data/3-8_Sq._Drive_Single_Hex_Impact_Socket_(Metric)_(G).json",
      ],
    },
    "industrial-sockets": {
      images: [
        "./assets/img/extension-sockets.jpg",
        "./assets/img/t4.jpg",
        "./assets/img/t7.jpg",
        "./assets/img/t17.jpg",
      ],
      data: [
        "./assets/data/1-2_Sq._Drive_Double_Hex_Industrial_Deep_Socket_(Metric)_(K).json",
        "./assets/data/1-2_Sq._Drive_Double_Hex_Industrial_Socket_(American)_(J).json",
        "./assets/data/1-2_Sq._Drive_Double_Hex_Industrial_Socket_(Metric)_(J).json",
        "./assets/data/1-4 Sq._Drive_Double_Hex_Industrial_Deep_Socket_(American)_(B).json",
        "./assets/data/1-4_Sq_ Drive_Double_Hex_Industrial_Socket_(Metric)_(A).json",
        "./assets/data/1-4_Sq._Drive_Double_Hex_Industrial_Deep_Socket_(Metric)_(B).json",
        "./assets/data/1-4_Sq._Drive_Double_Hex_Industrial_Socket_(American)_(A).json",
        "./assets/data/3-8_Sq._Drive_Double_Hex_Deep_Industrial_Socket_(American)_(F).json",
        "./assets/data/3-8_Sq._Drive_Double_Hex_Deep_Industrial_Sockets_(Metric)_(F).json",
        "./assets/data/3-8_Sq._Drive_Double_Hex_Industrial_Socket_(AMERICAN)_(E).json",
        "./assets/data/3-8_Sq._Drive_Double_Hex_Industrial_Socket_(Metric)_(E).json",
      ],
    },
    "universal-joints": {
      images: ["./assets/img/swivel-impact-joint.jpg"],
      data: ["./assets/data/universal-joints.json"],
    },
    "sliding-t-handles": {
      images: ["./assets/img/t-handle.jpg"],
      data: ["./assets/data/sliding-t-handle.json"],
    },
    "reversible-ratchets": {
      images: ["./assets/img/reversible-ratchet.jpg"],
      data: ["./assets/data/reversible-ratchets.json"],
    },
    "speed-braces": {
      data: ["./assets/data/speed-braces.json"],
    },
    "tool-kits": {
      images: ["./assets/img/tool-kit.jpg"],
      data: ["./assets/data/tool-kits.json"],
    },
    "angle-handle": {
      images: ["./assets/img/l-handle.jpg"],
      data: ["./assets/data/angle-handle.json"],
    },
    "square-drive-adaptors": {
      images: ["./assets/img/impact-square-drive-adaptor.jpg"],
      data: [
        "./assets/data/square-drive-adaptors.json",
        "./assets/data/impact-square-drive-adaptors.json",
      ],
    },
    "swivel-handles": {
      images: ["./assets/img/l-handle.jpg"],
      data: [
        "./assets/data/swivel-impact-sockets.json",
        "./assets/data/swivel-handles.json",
        "./assets/data/swivel-impact-joint.json",
      ],
    },
    "extension-bars": {
      images: ["./assets/img/extension-sockets.jpg"],
      data: [
        "./assets/data/impact-extension-bars.json",
        "./assets/data/extension-bars.json",
      ],
    },
    "stud-tightners": {
      images: ["./assets/img/stud-tightner.jpg"],
      data: [
        "./assets/data/1-2-Sq.-Drive-Stud-Tightner-(Metric).json",
        "./assets/data/1-2-Sq.-Drive-Stud-Tightner-(American-Bristish).json",
        "./assets/data/3-4-Sq.-Drive-Stud-Tightner-(Metric).json",
        "./assets/data/3-8-Sq.-Drive-Stud-Tightner-(Metric).json",
      ],
    },
    "slugging-wrenches": {
      images: ["./assets/img/slugging-wrench.jpg"],
      data: [
        "./assets/data/slugging-wrench-offset.json",
        "./assets/data/slugging-wrench-straight.json",
      ],
    },
    "hex-drivers": {
      images: ["./assets/img/hex-driver.jpg"],
      data: [
        "./assets/data/hex-driver.json",
        "./assets/data/3-8-Sq.-Impact-Drive-Hex-Adaptor.json",
        "./assets/data/1-2-Sq.-Drive-Hex-Adaptor-(American).json",
        "./assets/data/1-2-Sq.-Drive-Hex-Adaptor-(Metric).json",
        "./assets/data/3-4-Sq.-Impact-Drive-Hex-Adaptor-(Metric).json",
        "./assets/data/1-Sq.-Impact-Drive-Hex-Adaptor-(Metric).json",
      ],
    },
    "ratcheting-spanners": {
      images: ["./assets/img/ratchett-spinner.jpg"],
      data: [
        "./assets/data/ratcheting-spanner(American).json",
        "./assets/data/ratcheting-spanner(Metric).json",
      ],
    },
  };
  productsList = [
    {
      name: "Open Jaw Torque Wrenches",
      id: "open-jaw-torque-wrenches",
      image: "./assets/img/open-jaw-torque-wrench.jpg",
    },
    {
      name: "Torque Wrenches",
      id: "torque-wrenches",
      image: "./assets/img/torque_wrench_ratchet_type.jpg",
    },
    {
      name: "Socket",
      id: "impact-sockets",
      products: [
        {
          name: "Impact Sockets",
          id: "impact-sockets",
        },
        {
          name: "Industrial Sockets",
          id: "industrial-sockets",
        },
      ],
      image: "./assets/img/t7.jpg",
    },
    {
      name: "Universal Joints",
      id: "universal-joints",
      image: "./assets/img/swivel-impact-joint.jpg",
    },
    {
      name: "Stud Tightners",
      id: "stud-tightners",
      image: "./assets/img/stud-tightner.jpg",
    },
    {
      name: "Extension Bars",
      id: "extension-bars",
      image: "./assets/img/extension-sockets.jpg",
    },
    {
      name: "Square Drive Adaptors",
      id: "square-drive-adaptors",
      image: "./assets/img/impact-square-drive-adaptor.jpg",
    },
    {
      name: "Reversible Ratchets",
      id: "reversible-ratchets",
      image: "./assets/img/reversible-ratchet.jpg",
    },
    {
      name: "Sliding T Handles",
      id: "sliding-t-handles",
      image: "./assets/img/t-handle.jpg",
    },
    {
      name: "Angle Handles",
      id: "angle-handle",
      image: "./assets/img/l-handle.jpg",
    },
    {
      name: "Ratcheting Spanners",
      id: "ratcheting-spanners",
      image: "./assets/img/ratchett-spinner.jpg",
    },
    {
      name: "Swivel Handles",
      id: "swivel-handles",
      image: "./assets/img/l-handle.jpg",
    },
    {
      name: "Hex Drivers/Hex Adaptors",
      id: "hex-drivers",
      image: "./assets/img/hex-driver.jpg",
    },
    {
      name: "Slugging Wrenches",
      id: "slugging-wrenches",
      image: "./assets/img/slugging-wrench.jpg",
    },
    {
      name: "Tool Kits",
      id: "tool-kits",
      image: "./assets/img/tool-kit.jpg",
    },
  ];
}
