import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MapComponent } from "./map/map.component";
import { ToolComponent } from "./tool/tool.component";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule } from "@angular/common/http";
import { DownloadComponent } from "./download/download.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ContactComponent } from "./contact/contact.component";
import { ToastrModule, ToastContainerModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CalculatorComponent } from "./calculator/calculator.component";

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    ToolComponent,
    HomeComponent,
    DownloadComponent,
    SidebarComponent,
    ContactComponent,
    CalculatorComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
    }),
    ToastContainerModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
