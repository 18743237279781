import { Component, OnInit } from "@angular/core";
import { ConstantsService } from "../constants.service";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-tool",
  templateUrl: "./tool.component.html",
  styleUrls: ["./tool.component.css"],
})
export class ToolComponent implements OnInit {
  showSubProducts = false;
  productsInfo;
  images;
  allProducts = true;
  productId = "";
  OPEN_JAW_TABLE_DATA = [
    [
      "TW10",
      "2-10",
      "0.5",
      "3-14",
      "0.5",
      "0.3-1.4",
      "0.1",
      '5/8"',
      "342",
      "0.6",
    ],
    ["TW25", "5-25", "1", "5-35", "2", "0.5-3.5", "0.2", '5/8"', "365", "0.65"],
    ["TW50", "8-50", "2", "12-68", "2", "1-7", "0.2", '5/8"', "490", "1.15"],
    [
      "TW100",
      "20-100",
      "2",
      "25-135",
      "5",
      "3-14",
      "0.5",
      '5/8"',
      "587",
      "1.75",
    ],
    ["TW160", "40-160", "5", "50-220", "5", "5-23", "1", '5/8"', "600", "2.45"],
    [
      "TW250",
      "50-250",
      "5",
      "70-340",
      "10",
      "7-35",
      "1",
      '5/8"',
      "804",
      "3.10",
    ],
  ];
  constructor(
    private constants: ConstantsService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.params.subscribe((params) => {
      this.productId = params.id;
      if (params.id == "all") {
        this.allProducts = true;
      } else {
        this.allProducts = false;
        const toolInfo = this.constants.toolsDataInfo[params.id];
        if (toolInfo) {
          this.images = toolInfo.images;
          const dataObservables = toolInfo.data.map((path) => {
            return this.http.get(path);
          });
          forkJoin(dataObservables).subscribe((response) => {
            this.productsInfo = response;
          });
        }
      }
    });
  }
}
