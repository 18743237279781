import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Leaf from 'leaflet';
@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
	private leafletMap: any;
	private divIcon: any;
	constructor () { }

	ngOnInit () {
		this.divIcon = Leaf.divIcon({
			className: 'map-component-icon',
			html: '<span class="icon icon-location"></span>',
			iconAnchor: [21.25, 63],
			popupAnchor: [0, -59],
		});
	}

	ngAfterViewInit () {
		this.initMap();
	}

	initMap () {
		this.leafletMap = Leaf.map('mapid').setView([30.606430, 76.857832], 17);
		Leaf.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={token}', {
			maxZoom: 21,
			id: 'mapbox.streets',
			token: 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
		}).addTo(this.leafletMap);
	}
}
